import { all, takeEvery, put, call, select } from 'redux-saga/effects';

import actions from './actions';
import { unHandleError } from '../../constant/error';
import { showNotification } from '../appNotification/sagas';
import {
  addCategoryApi,
  updateCategoryApi,
  deleteCategoryApi,
  getAllDataAPi,
  changeStatusApi,
} from './api';

function* setLoader(value) {
  yield put(actions.setLoader(value));
}

function* getAllData() {
  const { limit, sortCol, sortVal, searchCategoryInput, page } = yield select(
    state => state.category.generalData,
  );

  yield setLoader(true);

  const apiData = { limit, sortCol, sortVal, searchCategoryInput, page };

  try {
    const data = yield call(getAllDataAPi, apiData);
    if (data && data.success) {
      yield put(actions.getAllDataSuccess(data));
    } else yield call(showNotification, data);
    yield setLoader(false);
  } catch (e) {
    yield setLoader(false);
    yield call(showNotification, { ...unHandleError });
  }
}

function* addData({ payload }) {
  yield setLoader(true);
  try {
    const data = yield call(addCategoryApi, payload.data);
    if (data && data.success) {
      yield call(getAllData);
      yield put(actions.addModal(false));
    } else yield call(showNotification, data);
    yield setLoader(false);
  } catch (e) {
    yield setLoader(false);
    yield call(showNotification, { ...unHandleError });
  }
}

function* updateData({ payload }) {
  yield setLoader(true);
  try {
    const data = yield call(updateCategoryApi, payload.data);
    if (data && data.success) {
      yield call(getAllData);
      yield put(actions.updateModal(false, null));
    } else yield call(showNotification, data);
    yield setLoader(false);
  } catch (e) {
    yield setLoader(false);
    yield call(showNotification, { ...unHandleError });
  }
}

function* deleteData({ payload }) {
  yield setLoader(true);
  try {
    const data = yield call(deleteCategoryApi, payload.id);
    if (data && data.success) {
      yield call(getAllData);
      yield put(actions.deleteModal(false, null));
    } else yield call(showNotification, data);
    yield setLoader(false);
  } catch (e) {
    yield setLoader(false);
    yield call(showNotification, { ...unHandleError });
  }
}
function* changeStatus({ payload }) {
  yield setLoader(true);
  try {
    const data = yield call(changeStatusApi, payload.data);
    if (data && data.success) {
      yield call(getAllData);
    } else yield call(showNotification, data);
    yield setLoader(false);
  } catch (e) {
    yield setLoader(false);
    yield call(showNotification, { ...unHandleError });
  }
}

function* changeGeneralData({ payload }) {
  yield put(actions.changeGeneralDataSuccess(payload.data));
  yield call(getAllData);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CATEGORY_GET_ALL_DATA, getAllData),
    takeEvery(actions.CATEGORY_ADD_DATA, addData),
    takeEvery(actions.CATEGORY_UPDATE_DATA, updateData),
    takeEvery(actions.CATEGORY_DELETE_DATA, deleteData),
    takeEvery(actions.CATEGORY_CHANGE_STATUS, changeStatus),
    takeEvery(actions.CHANGE_GENERAL_DATA, changeGeneralData),
  ]);
}
