import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import { store } from '../store';
import { handleLogin, getUserDetails, handleUserData, logoutHandler } from './api';
import { unHandleError } from '../../constant/error';
import { showNotification } from '../appNotification/sagas';
import permissions from '../../constant/permissions';
import { getLiveNotifications, onLogoutDestroyToken } from '../../firebase';

const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

const checkPermissions = roles => {
  let originalPermissions = {};
  if (roles) {
    roles.map(role => {
      originalPermissions = { ...originalPermissions, [role.name]: true };
    });
    return originalPermissions;
  } else return originalPermissions;
};

function* setLoader(value) {
  yield put(actions.setLoader(value));
}
export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    } else if (fakeApiCall) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: 'secret token',
        profile: 'Profile',
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* customLogin() {
  yield takeEvery(actions.CUSTOM_LOGIN_REQUEST, function* ({ payload }) {
    try {
      yield setLoader(true);
      const data = yield call(handleLogin, payload.data);
      const id_token = data.token;
      if (data.status === 200) {
        const userData = yield call(handleUserData, id_token);
        yield put({ type: actions.CHANGE_LOGOUT_STATE });
        yield put(actions.storeUser(userData?.data));
        yield put(actions.storeRole(userData?.data?.permissions));
        if (userData?.data?.permissions)
          yield put(actions.storeOriginalPermission(checkPermissions(userData?.data?.permissions)));
        yield put(actions.login(id_token));
        yield call(setSidebar);
      } else yield call(showNotification, data);
      yield setLoader(false);
    } catch (e) {
      console.log('e saga', e);
      yield setLoader(false);
      yield call(showNotification, { ...unHandleError });
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    if (store.getState().Auth.idToken) {
      yield call(onLogoutDestroyToken);
      yield call(logoutHandler);
      let LSToken = localStorage.getItem('fcm');
      if (LSToken) {
        localStorage.removeItem('fcm');
      }
      yield put({ type: actions.CUSTOM_LOGOUT });
      clearToken();
    }
  });
}

export function* getUser() {
  yield takeEvery(actions.GET_USER_DETAILS, function* () {
    const token = getToken().get('idToken');
    if (token) {
      try {
        const data = yield call(handleUserData, token);
        if (data.status === 200) {
          yield put(actions.storeUser(data?.data));
          yield put(actions.storeRole(data?.data?.permissions));
          if (data?.data?.permissions)
            yield put(actions.storeOriginalPermission(checkPermissions(data?.data?.permissions)));
          yield put({
            type: actions.LOGIN_SUCCESS,
            token,
            profile: 'Profile',
          });
          yield call(setSidebar);
        } else yield call(showNotification, data);
      } catch (e) {
        // yield setLoader(true);
        yield call(showNotification, { ...unHandleError });
      }
    }
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      yield put({ type: actions.CHANGE_LOGOUT_STATE });
      try {
        const data = yield call(handleUserData, token);
        if (data.status === 200) {
          yield put(actions.storeUser(data?.data));
          yield put(actions.storeRole(data?.data?.permissions));
          if (data?.data?.permissions)
            yield put(actions.storeOriginalPermission(checkPermissions(data?.data?.permissions)));
          yield put({
            type: actions.LOGIN_SUCCESS,
            token,
            profile: 'Profile',
          });
          yield call(setSidebar);
          const userPermissions = yield select(state => state.Auth.permissions);
          if (userPermissions[permissions.getLiveNotifications]) {
            yield call(getLiveNotifications, store.dispatch, data?.data?._id);
          }
        } else yield call(showNotification, data);
      } catch (e) {
        console.log(e);
        // yield setLoader(true);
        yield call(showNotification, { ...unHandleError });
      }
    }
  });
}
export function* setSidebar() {
  let options = [];
  const userPermissions = yield select(state => state.Auth.permissions);

  // if (userPermissions[permissions.listChannelsCategory]) {
  //   options.push({
  //     key: 'category',
  //     label: 'Categories',
  //     leftIcon: 'ion-ios-paper',
  //     is_admin: true,
  //   });
  // }

  options.push({
    key: 'dashboard',
    label: 'Dashboard',
    leftIcon: 'fa fa-tachometer',
    is_admin: true,
  });

  if (userPermissions[permissions.listRole]) {
    options.push({
      key: 'roles',
      label: 'Roles Management',
      leftIcon: 'fas fa-users-cog',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listSubadminRoles]) {
    options.push({
      key: 'subadmin',
      label: 'Sub Admins Management',
      leftIcon: 'fas fa-users-cog',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listWorkers]) {
    options.push({
      key: 'workers',
      label: 'Workers Management',
      leftIcon: 'fas fa-users-cog',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listJailCommissaries]) {
    options.push({
      key: 'jailcommissary',
      label: 'Jail Commissaries Management',
      leftIcon: 'fas fa-users-cog',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllKiosks]) {
    options.push({
      key: 'kiosk',
      label: 'Kiosks Management',
      leftIcon: 'fas fa-tablet-alt',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listPrisons]) {
    options.push({
      key: 'prisons',
      label: 'Prisons Management',
      leftIcon: 'fas fa-lock',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listInmates]) {
    options.push({
      key: 'inmates',
      label: 'Inmates Management',
      leftIcon: 'fas fa-user-lock',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listFamilyMembers]) {
    options.push({
      key: 'familymembers',
      label: 'Family Members Management',
      leftIcon: 'fas fa-people-arrows',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listSettings]) {
    options.push({
      key: 'generalSetting',
      label: 'General Settings',
      leftIcon: 'fa fa-cog',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listProducts]) {
    options.push({
      key: 'products',
      label: 'Products Management',
      leftIcon: 'fas fa-shopping-bag',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllCategories]) {
    options.push({
      key: 'categories',
      label: 'Categories Management',
      leftIcon: 'fa fa-list-alt',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listEmailTemplate]) {
    options.push({
      key: 'emailTemplate',
      label: 'Email Templates Management',
      leftIcon: 'fas fa-envelope-open-text',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.listStaticPages]) {
    options.push({
      key: 'staticpages',
      label: 'Static Pages Management',
      leftIcon: 'fas fa-file-signature',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllOrders]) {
    options.push({
      key: 'orders',
      label: 'Orders Management',
      leftIcon: 'fas fa-receipt',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllGrievances]) {
    options.push({
      key: 'grievances',
      label: 'Grievances Management',
      leftIcon: 'fas fa-comments',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllApprovals]) {
    options.push({
      key: 'approvals',
      label: 'Approvals Management',
      leftIcon: 'fas fa-check',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllNotifications]) {
    options.push({
      key: 'notifications',
      label: 'Broadcast Notifications',
      leftIcon: 'fas fa-broadcast-tower',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllCallLogs]) {
    options.push({
      key: 'voice-calls',
      label: 'Voice Call Management',
      leftIcon: 'fas fa-phone',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllCallLogs]) {
    options.push({
      key: 'video-calls',
      label: 'Video Chat Management',
      leftIcon: 'fas fa-video',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getAllCallLogs]) {
    options.push({
      key: 'dial-calls',
      label: 'Dial Call Management',
      leftIcon: 'fas fa-phone',
      is_admin: true,
    });
  }

  if (
    userPermissions[permissions.getCardTransactions] &&
    userPermissions[permissions.getWalletTransactions]
  ) {
    options.push({
      key: 'transactions',
      label: 'Transactions History',
      leftIcon: 'fa fa-history',
      is_admin: true,
    });
  }

  if (userPermissions[permissions.getKioskSearchHistory]) {
    options.push({
      key: 'kiosk-search-history',
      label: 'Kiosk Inmate Search History',
      leftIcon: 'fa fa-history',
      is_admin: true,
    });
  }

  yield put({
    type: actions.SET_SIDEBAR,
    payload: { options },
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(customLogin),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(getUser),
  ]);
}
