import { unHandleError } from '../constant/error';
const responseHelper = res => {
  console.log(res, res.response);
  if (res.status === 200) return { ...res, success: true };
  else if (res && (res.status == 401 || res.status === 403))
    return {
      ...res,
      logout: true,
      success: false,
      showNotification: true,
      type: 'error',
      message: res.message,
    };
  else if ((res && res.status === 400 && res.message) || (res && res.status === 500 && res.message))
    return {
      ...res,
      success: false,
      showNotification: true,
      type: 'error',
      message: res.message,
    };
  else if (res.response && (res.response.status == 401 || res.response.status === 403))
    return {
      ...res.response,
      logout: true,
      success: false,
      showNotification: true,
      type: 'error',
      message: res.response.data.message,
    };
  else if (
    (res.response && res.response.status === 400 && res.response.message) ||
    (res.response && res.response.status === 500 && res.response.message)
  )
    return {
      ...res.response,
      success: false,
      showNotification: true,
      type: 'error',
      message: res.response.data.message,
    };
  else
    return {
      ...res,
      ...unHandleError,
    };
};

export { responseHelper };
