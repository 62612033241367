import actions from './actions';

const fields = { category_name: '' };

const initState = {
  allData: [],
  showAddModal: false,
  showEditModal: false,
  showDeleteModal: false,
  showViewModal: false,
  eleDetails: [],
  deleteEleId: null,
  loader: false,
  fields,
  generalData: {
    searchCategoryInput: '',
    allEleCount: 0,
    limit: process.env.REACT_APP_PER_PAGE_LIMIT,
    page: 1,
    sortCol: '',
    sortValue: '',
  },
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.CATEGORY_GET_ALL_DATA_SUCCESS: {
      return {
        ...state,
        allData: action.payload.data,
        generalData: { ...state.generalData, allEleCount: action.payload.totalElements },
      };
    }
    case actions.CATEGORY_ADD_MODAL: {
      return { ...state, showAddModal: action.payload.bool, fields };
    }
    case actions.CATEGORY_DELETE_MODAL: {
      return { ...state, showDeleteModal: action.payload.bool, deleteEleId: action.payload.id };
    }
    case actions.CATEGORY_UPDATE_MODAL: {
      return {
        ...state,
        showEditModal: action.payload.bool,
        eleDetails: action.payload.data,
        fields: action.payload.data,
      };
    }
    case actions.CATEGORY_VIEW_MODAL: {
      return { ...state, showViewModal: action.payload.bool, eleDetails: action.payload.data };
    }
    case actions.FIELDS_CHANGE: {
      return { ...state, fields: { ...state.fields, ...action.payload.data } };
    }
    case actions.SET_LOADER: {
      return { ...state, loader: action.payload };
    }
    case actions.CHANGE_GENERAL_DATA_SUCCESS: {
      if (action.payload.data.reset) {
        return {
          ...state,
          generalData: {
            ...state.generalData,
            searchCategoryInput: '',
            sortCol: '',
            sortValue: '',
          },
        };
      }
      return { ...state, generalData: { ...state.generalData, ...action.payload.data } };
    }
    default:
      return state;
  }
}
