import { put } from 'redux-saga/effects';
import appNotificationActions from './actions';
import authActions from '../auth/actions';

export function* showNotification(data) {
  console.log('data',data);
  yield put(appNotificationActions.showNotification(data));
  yield put(appNotificationActions.showNotificationSuccess());
  if (data.logout) yield put(authActions.logout());
}
