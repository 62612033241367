import { takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { fetchNotifications } from './api';
import { unHandleError } from '../../constant/error';
import { showNotification } from '../appNotification/sagas';

export default function* getLiveNotifications() {
  yield takeEvery(actions.STORE_NOTIFICATIONS, function* ({ payload, ...res }) {
    try {
      const params = {
        page: payload?.data?.page,
        limit: payload?.data?.limit,
        prison: payload?.data?.prison,
      };
      const isBellRinging = payload?.bool === true ? true : false;
      const data = yield call(fetchNotifications, params);
      yield put(actions.storeNotificationsSuccess(data, isBellRinging));
    } catch (e) {
      yield call(showNotification, { ...unHandleError });
    }
  });
}
