import Api from '../../../library/helpers/apiHelper';
// import notificationHandler, { openNotificationWithIconError } from '../Notification/notification';
import apiConst from '../../../constant/api';

const api = new Api();

class AllAPICALL {
  getAllDashboard = async () => {
    try {
      const res = await api.get({ url: apiConst.dashboard, message: false });
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllStripeDashboard = async ({ ...data }) => {
    try {
      const res = await api.post({ url: apiConst.stripeDashboard, message: false, ...data });
      return res;
    } catch (e) {
      return e;
    }
  };

  changePassword = async ({ ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changePassword,
        ...data,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  forgotPassword = async ({ ...data }) => {
    try {
      const res = await api.post({ url: apiConst.forgotPassword, ...data });
      return res;
    } catch (e) {
      return e;
    }
  };

  resetPassword = async ({ token, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.resetPassword.replace(':token', token),
        ...data,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  updateProfile = async ({ ...data }) => {
    try {
      const res = await api.put({ url: apiConst.profileUpdate, ...data });
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteProfile = async ({ ...data }) => {
    try {
      const res = await api.delete({ url: apiConst.deleteProfile, ...data });
      console.log(res);
      return res;
    } catch (e) {
      return e;
    }
  };

  verifyUser = async ({ token }) => {
    try {
      const res = await api.patch({ url: apiConst.verifyUser.replace(':token', token) });
      return res;
    } catch (e) {
      return e;
    }
  };

  rolesList = async ({ ...data }) => {
    try {
      const res = await api.post({ url: apiConst.listRoles, ...data, message: false });
      return res;
    } catch (e) {
      return e;
    }
  };

  getRoleById = async ({ id }) => {
    try {
      const res = await api.get({
        url: apiConst.getRoleById.replace(':id', id),
        message: false,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  addRole = async ({ ...data }) => {
    try {
      const res = await api.post({ url: apiConst.createRole, ...data });
      console.log('res', res?.data?.docs);
      return res;
    } catch (e) {
      return e.response.data;
    }
  };

  deleteRole = async ({ id }) => {
    try {
      const res = await api.delete({ url: apiConst.deleteRole.replace(':id', id) });
      return res;
    } catch (e) {
      return e;
    }
  };

  updateRole = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateRole.replace(':id', id),
        // data: data?.data,
        // params: data?.params,
        ...data,
      });
      console.log('res', res?.data?.docs);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllPermissions = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.allPermissionsList,
        ...data,
        message: false,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  changeRoleActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changeStatus.replace(':id', id),
        ...data,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  getSettings = async () => {
    try {
      const res = await api.get({ url: apiConst.getSettings, message: false });
      return res;
    } catch (e) {
      return e;
    }
  };

  updateSettings = async ({ ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.updateSettings,
        ...data,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllEmailTemplates = async ({ ...data }) => {
    try {
      const res = await api.post({ url: apiConst.listEmailTemplate, message: false, ...data });
      return res;
    } catch (e) {
      return e;
    }
  };

  editEmailTemplate = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.editEmailTemplate.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getStaticPages = async ({ ...data }) => {
    try {
      const res = await api.post({ url: apiConst.listStaticPages, message: false, ...data });
      return res;
    } catch (e) {
      return e;
    }
  };

  editStaticPages = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.editStaticPages.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  listSubadminRoles = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.listSubadminRoles,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  verifySubadmin = async ({ ...data }) => {
    try {
      const res = await api.patch({ url: apiConst.verifySubadmin, ...data, message: true });
      return res;
    } catch (e) {
      return e;
    }
  };

  addSubadmin = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.addSubadmin,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getSubadminById = async ({ id }) => {
    try {
      const res = await api.get({
        url: apiConst.getSubadminById.replace(':id', id),
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  updateSubadmin = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateSubadmin.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteSubadmin = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteSubadmin.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  changeSubadminActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changeSubadminActiveStatus.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  verifyJailCommissary = async ({ ...data }) => {
    try {
      const res = await api.patch({ url: apiConst.verifyJailCommissary, ...data, message: true });
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllJailCommissaries = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllJailCommissaries,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  // getJailCommissary = async ({ id }) => {
  //   try {
  //     const res = await api.get({
  //       url: apiConst.getJailCommissaryById.replace(':id', id),
  //       message: false,
  //     });
  //     // console.log('res', res?.data);
  //     return res;
  //   } catch (e) {
  //     return e;
  //   }
  // };

  addJailCommissary = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.addJailCommissary,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteJailCommissary = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteJailCommissary.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  updateJailCommissary = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateJailCommissary.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  changeJailCommissaryActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changeJailCommissaryActiveStatus.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  resetJailCommissaryPassword = async ({ user, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.resetJailCommissaryPassword.replace(':id', user),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllPrisons = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllPrisons,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  createPrison = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.createPrison,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  updatePrisonDetails = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updatePrisonDetails.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deletePrison = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deletePrison.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  changePrisonActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changePrisonActiveStatus.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getPrisonByID = async id => {
    try {
      const res = await api.get({
        url: apiConst.getPrisonByID.replace(':id', id),
        message: false,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllInmates = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllInmates,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  addInmate = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.addInmate,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  importInmate = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.importInmate,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  resetInmatePassword = async ({ user, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.resetInmatePassword.replace(':id', user),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  updateInmate = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateInmate.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteInmate = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteInmate.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  changeInmateActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changeInmateActiveStatus.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllInmateClassifications = async ({ ...data }) => {
    try {
      const res = await api.get({
        url: apiConst.getAllInmateClassifications,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllFamilyMembers = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllFamilyMembers,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  addFamilyMember = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.addFamilyMember,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  updateFamilyMember = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateFamilyMember.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteFamilyMember = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteFamilyMember.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  changeFamilyMemberActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changeFamilyMemberActiveStatus.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllProducts = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllProducts,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  updateProduct = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateProduct.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  changeProductActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changeProductActiveStatus.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllCategories = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllCategories,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllOrders = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllOrders,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllGrievances = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllGrievances,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getGrievanceById = async ({ id }) => {
    try {
      const res = await api.get({
        url: apiConst.getGrievanceById.replace(':id', id),
        message: false,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  updateGrievance = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateGrievance.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteGrievance = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteGrievance.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllGrievanceStatuses = async ({ ...data }) => {
    try {
      const res = await api.get({
        url: apiConst.getAllGrievanceStatuses,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllGrievanceAssignees = async ({ id }) => {
    try {
      const res = await api.get({
        url: apiConst.getAllGrievanceAssignees.replace(':id', id),
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  createGrievanceNote = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.createGrievanceNote,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllApprovals = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllApprovals,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  manageApproval = async ({ ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.manageApproval,
        ...data,
        message: true,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getApprovalByTypes = async ({ ...data }) => {
    try {
      const res = await api.get({
        url: apiConst.getApprovalByTypes,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteApproval = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteApproval.replace(':id', id),
        message: true,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAdminSettings = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAdminSettings,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllKiosks = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllKiosks,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  addKiosk = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.addKiosk,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  updateKiosk = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateKiosk.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteKiosk = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteKiosk.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  changeKioskActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changeKioskActiveStatus.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  resendVerificationKiosk = async ({ ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.resendVerificationKiosk,
        ...data,
        message: true,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  resetKioskPassword = async ({ user, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.resetKioskPassword.replace(':id', user),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllNotifications = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllNotifications,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  sendNotifications = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.sendNotifications,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteNotifications = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteNotifications.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getCardTransactions = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getCardTransactions,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getWalletTransactions = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getWalletTransactions,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getTransactionTypes = async ({ ...data }) => {
    try {
      const res = await api.get({
        url: apiConst.getTransactionTypes,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllCallLogs = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllCallLogs,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getRecording = async ({ channel }) => {
    try {
      const res = await api.get({
        url: apiConst.getRecording.replace(':channel', channel),
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  joinCall = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.joinCall,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  dropCall = async ({ ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.dropCall,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  fetchAgoraToken = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.fetchAgoraToken,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllWorkers = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllWorkers,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  addWorker = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.addWorker,
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getWorkerById = async ({ id }) => {
    try {
      const res = await api.get({
        url: apiConst.getWorkerById.replace(':id', id),
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  updateWorker = async ({ id, ...data }) => {
    try {
      const res = await api.put({
        url: apiConst.updateWorker.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  deleteWorker = async ({ id }) => {
    try {
      const res = await api.delete({
        url: apiConst.deleteWorker.replace(':id', id),
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  changeWorkerActiveStatus = async ({ id, ...data }) => {
    try {
      const res = await api.patch({
        url: apiConst.changeWorkerActiveStatus.replace(':id', id),
        ...data,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  verifyWorker = async ({ ...data }) => {
    try {
      const res = await api.patch({ url: apiConst.verifyWorker, ...data, message: true });
      return res;
    } catch (e) {
      return e;
    }
  };

  getKioskSearchHistory = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getKioskSearchHistory,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  addChatMessage = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.addChatMessage,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getAllChatMessages = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getAllChatMessages,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };

  getLiveNotifications = async ({ ...data }) => {
    try {
      const res = await api.post({
        url: apiConst.getLiveNotifications,
        ...data,
        message: false,
      });
      // console.log('res', res?.data);
      return res;
    } catch (e) {
      return e;
    }
  };
}

export default AllAPICALL;
