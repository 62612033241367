import { notification } from 'antd';
import { Map } from 'immutable';
import { isValidPhoneNumber } from 'libphonenumber-js';

export function clearToken() {
  localStorage.removeItem('id_token');
}

export function getToken() {
  try {
    let idToken = null;
    if (localStorage.getItem('id_token')) idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => (number > 1 ? 's' : '');
  const number = num => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `${days} day${numberEnding(days)}`;
      }
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const month = months[givenTime.getUTCMonth()];
      const day = number(givenTime.getUTCDate());
      return `${day} ${month}`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  }
  if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

const getBase64 = file => {
  if (file)
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  else new Promise((resolve, reject) => reject());
};

export const isFileSizeValid = (file, fileSize) => {
  return file.size / 1024 / 1024 < fileSize;
};

export const isImage = file => {
  return file['type'].split('/')[0] === 'image';
};

export const handleFileChange = (e, setFile, setNewImage, fileSize = 5) => {
  if (isFileSizeValid(e.file, fileSize) && isImage(e.file)) {
    setFile(e.file);
    getBase64(e.file)
      .then(data => setNewImage(data))
      .catch(e => console.log(e));
  } else {
    notification.error({
      message: 'Error',
      description: `${
        e.file.size / 1024 / 1024 > fileSize
          ? `Please upload file less than ${fileSize} MB`
          : 'Please upload a valid image file'
      }`,
    });
  }
};

export const isValidPhone = (phone, code) => {
  return isValidPhoneNumber(`+${code}${phone}`);
};
