const actions = {
  STORE_NOTIFICATIONS: 'STORE_NOTIFICATIONS',
  STORE_NOTIFICATIONS_SUCCESS: 'STORE_NOTIFICATIONS_SUCCESS',
  CHANGE_RINGING_STATUS: 'CHANGE_RINGING_STATUS',
  storeNotifications: (data, bool) => ({
    type: actions.STORE_NOTIFICATIONS,
    payload: { data, bool },
  }),
  storeNotificationsSuccess: (data, bool) => ({
    type: actions.STORE_NOTIFICATIONS_SUCCESS,
    payload: { data, bool },
  }),
  changeRingingStatus: data => ({ type: actions.CHANGE_RINGING_STATUS, payload: data }),
};
export default actions;
