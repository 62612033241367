const permissions = {
  dashboard: 'dashboard_count',
  addEmailTemplate: '/email-template/create',
  updateEmailTemplate: '/email-template/update/:id',
  deleteEmailTemplate: '/email-template/delete/:id',
  viewEmailTemplate: '/email-template/lists/:id',
  updateProfile: '/profile/update',
  deleteProfile: '/profile/delete',
  resendVerificationLink: '/profile/resend-verify',
  changePassword: '/profile/change-password',
  listPermissions: '/permissions/lists',
  verifyUser: '/auth/verify-user/:id',
  addRole: '/roles/create',
  viewRole: '/roles/lists/:id',
  listRole: '/roles/lists',
  updateRole: '/roles/update/:id',
  deleteRole: '/roles/delete/:id',
  changeRoleActiveStatus: '/roles/update/status/:id',
  listSettings: '/settings/list',
  listEmailTemplate: '/email-template/lists',
  editEmailTemplate: '/email-template/update/:id',
  listStaticPages: '/static-page/lists',
  viewStaticPage: '/static-page/lists/:slug',
  editStaticPages: '/static-page/update/:id',
  listSubadminRoles: '/sub-admin/users/lists',
  viewSubadmin: '/sub-admin/users/lists/:id',
  verifySubadmin: '/sub-admin/users/resend-verification',
  addSubadmin: '/sub-admin/users/create',
  editSubadmin: '/sub-admin/users/update/:id',
  deleteSubadmin: '/sub-admin/users/delete/:id',
  changeSubadminActiveStatus: '/sub-admin/users/update/status/:id',
  verifyJailCommissary: '/commissary/users/resend-verification',
  listJailCommissaries: '/commissary/users/lists',
  addJailCommissary: '/commissary/users/create',
  // viewJailCommissary: '/commissary/users/lists/:id',
  editJailCommissary: '/commissary/users/update/:id',
  deleteJailCommissary: '/commissary/users/delete/:id',
  changeJailCommissaryActiveStatus: '/commissary/users/update/status/:id',
  resetJailCommissaryPassword: '/commissary/users/reset-password/:id',
  listPrisons: '/prison/lists',
  viewPrison: '/prison/lists/:id',
  addPrison: '/prison/create',
  editPrison: '/prison/update/:id',
  deletePrison: '/prison/delete/:id',
  changePrisonActiveStatus: '/prison/update/status/:id',
  listInmates: '/inmate/users/lists',
  importInmate: '/inmate/users/import',
  viewInmate: '/inmate/users/lists/:id',
  addInmate: '/inmate/users/create',
  updateInmate: '/inmate/users/update/:id',
  deleteInmate: '/inmate/users/delete/:id',
  changeInmateActiveStatus: '/inmate/users/update/status/:id',
  resetInmatePassword: '/inmate/users/reset-password/:id',
  listFamilyMembers: '/family/users/lists',
  viewFamilyMember: '/family/users/lists/:id',
  addFamilyMember: '/family/users/create',
  updateFamilyMember: '/family/users/update/:id',
  deleteFamilyMember: '/family/users/delete/:id',
  changeFamilyMemberActiveStatus: '/family/users/update/status/:id',
  listProducts: '/commissary/product/lists',
  viewProduct: '/commissary/product/lists/:id',
  updateProduct: '/commissary/product/update/:id',
  changeProductActiveStatus: '/commissary/product/update/status/:id',
  getAllOrders: '/commissary/orders/lists',
  viewOrder: '/commissary/orders/lists/:id',
  getAllCategories: '/commissary/category/lists',
  viewCategory: '/commissary/category/lists/:id',
  getAllGrievances: '/grievance/lists',
  updateGrievance: '/grievance/update/:id',
  deleteGrievance: '/grievance/delete/:id',
  viewGrievance: '/grievance/lists/:id',
  getAllGrievanceAssignees: '/grievance/lists/assignees/:id',
  createGrievanceNote: '/grievance/note/create',
  getAllApprovals: '/approval/lists',
  manageApproval: '/approval/manage',
  getApprovalById: '/approval/lists/:id',
  getApprovalTypes: '/approval/types',
  deleteApproval: '/approval/delete/:id',
  getAllKiosks: '/kiosk/users/lists',
  addKiosk: '/kiosk/users/create',
  updateKiosk: '/kiosk/users/update/:id',
  deleteKiosk: '/kiosk/users/delete/:id',
  changeKioskActiveStatus: '/kiosk/users/update/status/:id',
  resendVerificationKiosk: '/kiosk/users/resend-verification',
  resetKioskPassword: '/kiosk/users/reset-password/:id',
  getAllNotifications: '/broadcast-notification/lists',
  sendNotifications: '/broadcast-notification/create',
  deleteNotifications: '/broadcast-notification/delete/:id',
  getAllCallLogs: '/communication/call/lists',
  getRecording: '/communication/call/recording/:channel',
  joinCall: '/communication/call/join',
  dropCall: '/communication/call/drop',
  addChatMessage: '/communication/chat/add',
  getAllChatMessages: '/communication/chat/lists',
  getCardTransactions: '/payments/lists',
  getWalletTransactions: '/wallet/lists',
  getTransactionTypes: '/wallet/transactions/lists',
  listWorkers: '/worker/users/lists',
  getWorkerById: '/worker/users/lists/:id',
  addWorker: '/worker/users/create',
  updateWorker: '/worker/users/update/:id',
  deleteWorker: '/worker/users/delete/:id',
  changeWorkerActiveStatus: '/worker/users/update/status/:id',
  verifyWorker: '/worker/users/resend-verification',
  getKioskSearchHistory: '/kiosk/search-history/lists',
  getLiveNotifications: '/notification/lists',
};

export default permissions;
