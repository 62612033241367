import AllAPICALL from '../../containers/Pages/AllAPICALL/allAPICALL';

const apiCall = new AllAPICALL();

const fetchNotifications = async ({
  page = 1,
  limit = process.env.REACT_APP_PER_PAGE_LIMIT,
  prison = undefined,
}) => {
  const { data } = await apiCall.getLiveNotifications({
    params: {
      page,
      limit,
    },
    data: {
      prison: prison,
    },
  });
  return data;
};

export { fetchNotifications };
