export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/reset-password',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
  VERIFY_USER: '/verify',
};

export const PRIVATE_ROUTE = {
  MAIN: '',
  DASHBOARD: 'dashboard',
  EDITPROFILE: 'edit-profile',
  ROLESLIST: 'roles',
  CHANGE_PASSWORD: 'change-password',
  EMAIL_TEMPLATES: 'emailTemplate',
  STATIC_PAGES: 'staticpages',
  ADDROLE: 'add-roles',
  EDITROLE: 'edit-roles/:id/:type',
  SUBADMIN_ROLES_LIST: 'subadmin',
  SUBADMIN_ADD_ROLE: 'subadmin/add-roles',
  SUBADMIN_EDIT_ROLE: 'subadmin/edit-roles/:id',
  GENERAL_SETTING: 'generalSetting',
  JAIL_COMMISSARY_LIST: 'jailcommissary',
  PRISONS_LIST: 'prisons',
  PRISON_ADD: 'prisons/add-prison',
  PRISON_EDIT: 'prisons/edit-prison/:id',
  INMATES_LIST: 'inmates',
  FAMILY_MEMBERS_LIST: 'familymembers',
  PRODUCTS_LIST: 'products',
  CATEGORIES_LIST: 'categories',
  ORDERS: 'orders',
  GRIEVANCES_LIST: 'grievances',
  EDIT_GRIEVANCE: 'grievances/:id',
  GRIEVANCE_NOTES: 'grievances/:id/notes',
  APPROVALS_LIST: 'approvals',
  NOTIFICATIONS_LIST: 'notifications',
  KIOSK_LIST: 'kiosk',
  WORKERS_LIST: 'workers',
  ADD_WORKER: 'worker/add-worker',
  EDIT_WORKER: 'worker/edit-worker/:id',
  TRANSACTIONS: 'transactions',
  VOICE_CALL_LOGS: 'voice-calls',
  VIDEO_CALL_LOGS: 'video-calls',
  DIAL_CALL_LOGS: 'dial-calls',
  KIOSK_SEARCH_HISTORY: 'kiosk-search-history',
  LIVE_NOTIFICATIONS: 'live-notifications',
  VOICE_CALL_SCREEN: 'voice-call-screen',
  VIDEO_CALL_SCREEN: 'video-call-screen',
};
