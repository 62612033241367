import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';

const Notification = () => {
  const { type, showNotification, message } = useSelector(state => state.notification);
  useEffect(() => {
    if (showNotification) {
      notification[type]({
        message: type,
        description: message,
      });
    }
  }, [showNotification]);
  return <React.Fragment />;
};

export default Notification;
