import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import Logger from './library/helpers/logger';
import Notification from './Notifications';
import ErrorBoundary from './ErrorBoundary';
import Loader from '@iso/components/utility/loader';
import { messaging, onMessageListener } from './firebase';

const App = () => {
  useEffect(() => {
    onMessageListener()
      .then(payload => {
        // console.log(payload);
        // notification.info({
        //   message: payload.data.title,
        //   description: payload.data.body,
        // });
        // setNotification({
        //   title: payload.data.title,
        //   description: payload.data.body,
        // });
        // console.log(payload);
      })
      .catch(err => console.log('failed: ', err));
  }, []);

  return (
    <Provider store={store}>
      <AppProvider>
        <React.Fragment>
          <GlobalStyles />
          <Notification />
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Router>
                <Routes />
              </Router>
            </Suspense>
          </ErrorBoundary>
        </React.Fragment>
      </AppProvider>
    </Provider>
  );
};

Boot()
  .then(() => App())
  .catch(error => Logger.log(error));

export default App;
