const actions = {
  CATEGORY_GET_ALL_DATA: 'CATEGORY_GET_ALL_DATA',
  CATEGORY_GET_ALL_DATA_SUCCESS: 'CATEGORY_GET_ALL_DATA_SUCCESS',
  CATEGORY_ADD_MODAL: 'CATEGORY_ADD_MODAL',
  CATEGORY_DELETE_MODAL: 'CATEGORY_DELETE_MODAL',
  CATEGORY_UPDATE_MODAL: 'CATEGORY_UPDATE_MODAL',
  CATEGORY_VIEW_MODAL: 'CATEGORY_VIEW_MODAL',
  CATEGORY_ADD_DATA: 'CATEGORY_ADD_DATA',
  CATEGORY_UPDATE_DATA: 'CATEGORY_UPDATE_DATA',
  CATEGORY_DELETE_DATA: 'CATEGORY_DELETE_DATA',
  CATEGORY_CHANGE_STATUS: 'CATEGORY_CHANGE_STATUS',
  FIELDS_CHANGE: 'CATEGORY_FIELDS_CHANGE',
  SET_LOADER: 'CATEGORY_SET_LOADER',
  CHANGE_GENERAL_DATA: 'CATEGORY_GENERAL_DATA',
  CHANGE_GENERAL_DATA_SUCCESS: 'CATEGORY_GENERAL_DATA_SUCCESS',
  getAllData: () => ({
    type: actions.CATEGORY_GET_ALL_DATA,
  }),
  getAllDataSuccess: data => ({
    type: actions.CATEGORY_GET_ALL_DATA_SUCCESS,
    payload: data,
  }),
  addModal: bool => ({
    type: actions.CATEGORY_ADD_MODAL,
    payload: { bool },
  }),
  deleteModal: (bool, id) => ({
    type: actions.CATEGORY_DELETE_MODAL,
    payload: { bool, id },
  }),
  updateModal: (bool, data) => ({
    type: actions.CATEGORY_UPDATE_MODAL,
    payload: { bool, data },
  }),
  viewModal: (bool, data) => ({
    type: actions.CATEGORY_VIEW_MODAL,
    payload: { bool, data },
  }),
  addData: data => ({
    type: actions.CATEGORY_ADD_DATA,
    payload: { data },
  }),
  deleteData: id => ({
    type: actions.CATEGORY_DELETE_DATA,
    payload: { id },
  }),
  updateData: data => ({
    type: actions.CATEGORY_UPDATE_DATA,
    payload: { data },
  }),
  changeStatus: data => ({
    type: actions.CATEGORY_CHANGE_STATUS,
    payload: { data },
  }),
  changeFields: data => ({
    type: actions.FIELDS_CHANGE,
    payload: { data },
  }),
  setLoader: value => ({
    type: actions.SET_LOADER,
    payload: value,
  }),
  changeGeneralData: data => ({
    type: actions.CHANGE_GENERAL_DATA,
    payload: { data },
  }),
  changeGeneralDataSuccess: data => ({
    type: actions.CHANGE_GENERAL_DATA_SUCCESS,
    payload: { data },
  }),
};
export default actions;
