/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import Logger from '@iso/lib/helpers/logger';
import Api from '../../library/helpers/apiHelper';
import { responseHelper } from '../helper';
import apiConst from '../../constant/api';

const api = new Api();

const getAllDataAPi = async ({ limit, sortCol, sortVal, searchCategoryInput, page }) => {
  const values = {};
  values.sortCol = sortCol;
  values.sortVal = sortVal;
  values.query_search = searchCategoryInput;
  try {
    const res = await api.post(
      apiConst.getCategory.replace(':pageNumber', page).replace(':limit', limit),
      {
        data: values,
      },
    );
    return responseHelper(res);
  } catch (e) {
    Logger.log(e);
    return e;
  }
};

const addCategoryApi = async data => {
  try {
    const res = await api.post(apiConst.addChannelCategory, {
      data,
    });
    return responseHelper(res);
  } catch (e) {
    Logger.log(e);
    return e;
  }
};

const updateCategoryApi = async data => {
  try {
    const res = await api.post(apiConst.editChannelDetails, {
      data: { id: data.id, category_name: data.category_name },
    });
    return responseHelper(res);
  } catch (e) {
    Logger.log(e);
    return e;
  }
};

const deleteCategoryApi = async id => {
  try {
    const res = await api.post(apiConst.deleteChannel, {
      data: { id },
    });
    return responseHelper(res);
  } catch (e) {
    Logger.log(e);
    return e;
  }
};

const changeStatusApi = async ({ id, value }) => {
  try {
    const res = await api.post(apiConst.changeStatus, {
      data: { category_id: id, is_active: !value },
    });
    return responseHelper(res);
  } catch (e) {
    Logger.log(e);
    return e;
  }
};

export { getAllDataAPi, deleteCategoryApi, updateCategoryApi, addCategoryApi, changeStatusApi };
