import { initializeApp } from 'firebase/app';
import { onMessage, getToken, getMessaging, deleteToken } from 'firebase/messaging';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { doc, onSnapshot } from 'firebase/firestore';
import notificationActions from './redux/notification/actions';

const firebaseConfig = {
  apiKey: 'AIzaSyBBAA00UvxsTtVdpU616HX3UrbAcM1uHNM',
  authDomain: 'jail-connect.firebaseapp.com',
  projectId: 'jail-connect',
  storageBucket: 'jail-connect.appspot.com',
  messagingSenderId: '776206377742',
  appId: '1:776206377742:web:5039403a6229b5996eed5f',
  measurementId: 'G-WWST6MSFJZ',
  databaseURL: 'https://jail-connect-default-rtdb.firebaseio.com',
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const database = getDatabase(firebaseApp);
const messaging = getMessaging(firebaseApp);
navigator.serviceWorker.getRegistrations().then(function (registrations) {
  registrations.forEach(function (v) {
    console.log('service worker: ' + v.scope);
  });
});

export const getLiveNotifications = async (dispatch, userId) => {
  let isBellRinging = false; //Bell won't ring when the action will be dispatched for the first time (i.e when app reloads)
  onSnapshot(doc(db, 'live-notification', `${userId}`), async doc => {
    dispatch(notificationActions.storeNotifications(null, isBellRinging));
    isBellRinging = true;
  });
};

export const requestToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
    });
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      localStorage.setItem('fcm', currentToken);
    } else {
      // console.log('No registration token available. Request permission to generate one.');
      localStorage.removeItem('fcm');
    }
  } catch (err) {
    // console.log('An error occurred while retrieving token. ', err);
    localStorage.removeItem('fcm');
  }
};

export { messaging, db, database };

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      // console.log('Message received in foreground. ', payload);
      // navigator.serviceWorker
      //   .getRegistration('/firebase-cloud-messaging-push-scope')
      //   .then(registration => {
      //     registration.showNotification(payload?.notification.title, payload?.notification);
      //   });
      resolve(payload);
    });
  });

export const onLogoutDestroyToken = () => {
  try {
    deleteToken(messaging, payload => {});
  } catch (error) {
    console.log(error);
  }
};
