import Api from '../../library/helpers/apiHelper';
import { responseHelper } from '../helper';
import apiConst from '../../constant/api';
const api = new Api();

const getUserDetails = async token => {
  try {
    // const res = await api.post(
    //   apiConst.getUserDetails.replace(':pageNumber', 1).replace(':limit', 1),
    // );
    const res = await api.post(apiConst.login, {
      data: { email: 'fancall@example.com', password: 'fancall@example.com' },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const handleUserData = async id_token => {
  try {
    const res = await api.post({
      url: apiConst.profile,
      headers: { Authorization: `Bearer ${id_token}` },
      data: {
        platform: 1,
        fcm: localStorage.getItem('fcm') ?? undefined,
      },
      message: false,
    });
    return res;
  } catch (e) {
    console.log('e', e.response.data);
    return e;
  }
};

const handleLogin = async fields => {
  try {
    const res = await api.post({ url: apiConst.login, data: fields });
    console.log('res', res);
    return res;
  } catch (e) {
    console.log('e', e);
    return e;
  }
};

const getUserPermission = async token => {
  try {
    const res = await api.post(apiConst.getPermission, {
      data: { token },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e.response.data;
  }
};

const logoutHandler = async () => {
  try {
    const res = await api.post({
      url: apiConst.logout,
      message: false,
    });
    // console.log('res', res?.data);
    return res;
  } catch (e) {
    return e;
  }
};

export { getUserDetails, handleLogin, getUserPermission, handleUserData, logoutHandler };
