import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
import { getToken } from '@iso/lib/helpers/utility';

const App = lazy(() => import('./containers/App/App'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    Component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    Component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    Component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    Component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    Component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    Component: lazy(() => import('@iso/containers/Pages/ForgotPassword/ForgotPassword')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    Component: lazy(() => import('@iso/containers/Pages/ResetPassword/ResetPassword')),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_USER,
    Component: lazy(() => import('@iso/containers/Pages/Verify')),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    Component: lazy(() => import('@iso/containers/Authentication/Auth0/Auth0Callback')),
  },
];

function PrivateRoute({ children, ...rest }) {
  let isLoggedIn = useSelector(state => state.Auth.idToken);
  let isLSToken = localStorage.getItem('id_token');
  let isLoggedOut = useSelector(state => state.Auth.isLogout);
  if (!isLoggedIn) isLoggedIn = getToken().get('idToken');

  return (
    <Route
      {...rest}
      render={({ location }) =>
      isLSToken && !isLoggedOut ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              // state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  let isLoggedIn = useSelector(state => state.Auth.idToken);
  let isLoggedOut = useSelector(state => state.Auth.isLogout);
  return (
    <Switch>
      {publicRoutes.map(({ Component, path, exact = false }, index) => (
        <Route key={index} path={path} exact={exact}>
          <Component />
        </Route>
      ))}
    <PrivateRoute path="/admin">{isLoggedIn ? <App /> : <Loader />}</PrivateRoute>
    </Switch>
  );
}
