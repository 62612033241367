const apiConst = {
  login: '/auth/login',
  logout: '/profile/logout',
  profile: '/profile/me',
  getPermission: '/api/v1/admin/get-permission',
  dashboard: '/dashboard/lists',
  stripeDashboard: '/dashboard/stripe/lists',
  verifyUser: '/auth/verify-user/:token',
  listRoles: '/roles/lists',
  profileUpdate: '/profile/update',
  deleteProfile: '/profile/delete',
  forgotPassword: '/auth/forgot-password',
  changePassword: '/profile/change-password',
  resetPassword: '/auth/reset-password/:token',
  listEmailTemplate: '/email-template/lists',
  editEmailTemplate: '/email-template/update/:id',
  listStaticPages: '/static-page/lists',
  editStaticPages: '/static-page/update/:id',
  allPermissionsList: '/permissions/lists',
  createRole: '/roles/create',
  deleteRole: '/roles/delete/:id',
  getRoleById: '/roles/lists/:id',
  updateRole: '/roles/update/:id',
  changeStatus: '/roles/update/status/:id',
  getSettings: '/settings/list',
  updateSettings: '/settings/update',
  listSubadminRoles: '/sub-admin/users/lists',
  verifySubadmin: '/sub-admin/users/resend-verification',
  addSubadmin: '/sub-admin/users/create',
  getSubadminById: '/sub-admin/users/lists/:id',
  updateSubadmin: '/sub-admin/users/update/:id',
  deleteSubadmin: '/sub-admin/users/delete/:id',
  changeSubadminActiveStatus: '/sub-admin/users/update/status/:id',
  verifyJailCommissary: '/commissary/users/resend-verification',
  resetJailCommissaryPassword: '/commissary/users/reset-password/:id',
  getAllJailCommissaries: '/commissary/users/lists',
  // getJailCommissaryById: '/commissary/users/lists/:id',
  addJailCommissary: '/commissary/users/create',
  deleteJailCommissary: '/commissary/users/delete/:id',
  updateJailCommissary: '/commissary/users/update/:id',
  changeJailCommissaryActiveStatus: '/commissary/users/update/status/:id',
  getAllPrisons: '/prison/lists',
  createPrison: '/prison/create',
  updatePrisonDetails: '/prison/update/:id',
  deletePrison: '/prison/delete/:id',
  changePrisonActiveStatus: '/prison/update/status/:id',
  getPrisonByID: '/prison/lists/:id',
  getAllInmates: '/inmate/users/lists',
  addInmate: '/inmate/users/create',
  importInmate: '/inmate/users/import',
  updateInmate: '/inmate/users/update/:id',
  deleteInmate: '/inmate/users/delete/:id',
  resetInmatePassword: '/inmate/users/reset-password/:id',
  changeInmateActiveStatus: '/inmate/users/update/status/:id',
  getAllInmateClassifications: '/inmate/users/classification/lists',
  getAllFamilyMembers: '/family/users/lists',
  addFamilyMember: '/family/users/create',
  updateFamilyMember: '/family/users/update/:id',
  deleteFamilyMember: '/family/users/delete/:id',
  changeFamilyMemberActiveStatus: '/family/users/update/status/:id',
  getAllProducts: '/commissary/product/lists',
  updateProduct: '/commissary/product/update/:id',
  changeProductActiveStatus: '/commissary/product/update/status/:id',
  getAllCategories: '/commissary/category/lists',
  getAllOrders: '/commissary/orders/lists',
  getAllGrievances: '/grievance/lists',
  getGrievanceById: '/grievance/lists/:id',
  updateGrievance: '/grievance/update/:id',
  deleteGrievance: '/grievance/delete/:id',
  getAllGrievanceStatuses: '/grievance/lists/status',
  getAllGrievanceAssignees: '/grievance/lists/assignees/:id',
  createGrievanceNote: '/grievance/note/create',
  getAllApprovals: '/approval/lists',
  manageApproval: '/approval/manage',
  getApprovalById: '/approval/lists/:id',
  getApprovalByTypes: '/approval/types',
  deleteApproval: '/approval/delete/:id',
  getAllKiosks: '/kiosk/users/lists',
  addKiosk: '/kiosk/users/create',
  updateKiosk: '/kiosk/users/update/:id',
  deleteKiosk: '/kiosk/users/delete/:id',
  changeKioskActiveStatus: '/kiosk/users/update/status/:id',
  resendVerificationKiosk: '/kiosk/users/resend-verification',
  resetKioskPassword: '/kiosk/users/reset-password/:id',
  getAllNotifications: '/broadcast-notification/lists',
  sendNotifications: '/broadcast-notification/create',
  deleteNotifications: '/broadcast-notification/delete/:id',
  getCardTransactions: '/payments/lists',
  getWalletTransactions: '/wallet/lists',
  getTransactionTypes: '/wallet/transactions/lists',
  getAllCallLogs: '/communication/call/lists',
  getRecording: '/communication/call/recording/:channel',
  joinCall: '/communication/call/join',
  dropCall: '/communication/call/drop',
  getAllWorkers: '/worker/users/lists',
  getWorkerById: '/worker/users/lists/:id',
  addWorker: '/worker/users/create',
  updateWorker: '/worker/users/update/:id',
  deleteWorker: '/worker/users/delete/:id',
  changeWorkerActiveStatus: '/worker/users/update/status/:id',
  verifyWorker: '/worker/users/resend-verification',
  getKioskSearchHistory: '/kiosk/search-history/lists',
  addChatMessage: '/communication/chat/add',
  getAllChatMessages: '/communication/chat/lists',
  getLiveNotifications: '/notification/lists',
};

export default apiConst;
