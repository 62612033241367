import actions from './actions';

const initState = { notifications: [], allElement: null, isBellRinging: false };

export default function liveNotifications(state = initState, action) {
  switch (action.type) {
    case actions.STORE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload?.data?.docs,
        allElement: action.payload?.data?.totalDocs,
        isBellRinging: action.payload?.bool,
      };
    case actions.CHANGE_RINGING_STATUS:
      return {
        ...state,
        isBellRinging: action.payload,
      };
    default:
      return state;
  }
}
